import Dashboard from '@/components/layout/Dashboard';
import DemoForm from '@/pages/DemoForm';
import Home from '@/pages/Home';
import Import from '@/pages/Import';
import Payee from '@/pages/Payee';
import Settings from '@/pages/Settings';

import InternalMessages from '@/pages/messages/InternalMessages';
import Messages from '@/pages/messages/Messages';
import Notifications from '@/pages/messages/Notifications';

import BillsImport from '@/pages/import/BillsImport';

import AccountingSettings from '@/pages/settings/AccountingSettings';
import BillSettings from '@/pages/settings/BillSettings';
import CurrenciesProperties from '@/pages/settings/CurrenciesProperties';
import NotificationSettings from '@/pages/settings/Notifications';
import OrganizationInfo from '@/pages/settings/OrganizationInfo';
import PayersSettings from '@/pages/settings/PayersSettings';
import PaymentsSettings from '@/pages/settings/PaymentsSettings';
import Settlements from '@/pages/settings/Settlements';
import ShopSettings from '@/pages/settings/ShopSettings';

import ArchivedPayers from '@/pages/ArchivedPayers';
import Payers from '@/pages/Payers';
import BillForm from '@/pages/bills/BillForm';
import BankStatements from '@/pages/payments/BankStatements';
import PaymentForm from '@/pages/payments/PaymentForm';
import Payments from '@/pages/payments/Payments';

import BillingDocuments from '@/pages/bills/BillingDocuments';
import BillingDocumentsForm from '@/pages/bills/BillingDocumentsForm';
import BillingPlans from '@/pages/bills/BillingPlans';
import Bills from '@/pages/bills/Bills';
import FormConfiguration from '@/pages/forms/FormConfiguration';
import ImportPayers from '@/pages/import/ImportPayers';
import BalanceReport from '@/pages/reports/BalanceReport';
// import SettlementReport from '@/pages/reports/SettlementReport';
import FormsSettings from '@/pages/settings/FormsSettings';
import AccessManagement from '@/pages/settings/access/AccessManagement';

import AvailabilityForm from '@/pages/shop/AvailabilityForm';
import AvailabilityImport from '@/pages/shop/AvailabilityImport';
import Offer from '@/pages/shop/Offer';
import Orders from '@/pages/shop/Orders';
import ProductSets from '@/pages/shop/ProductSets';
import Products from '@/pages/shop/Products';
import ShopIndex from '@/pages/shop/ShopIndex';

export default {
  path: '',
  component: Dashboard,
  children: [
    {
      path: '',
      component: Home,
    },
    {
      path: '/tenant/add',
      component: DemoForm,
    },
    {
      path: '/payee/:payeeId?',
      component: Payee,
      props: true,
      children: [
        {
          path: 'payers',
          component: Payers,
        },
        {
          path: 'payers/archived',
          component: ArchivedPayers,
        },
        {
          path: 'import',
          component: Import,
          children: [
            {
              path: 'payers',
              component: ImportPayers,
            },
          ],
        },
        {
          path: 'shop',
          component: ShopIndex,
          children: [
            {
              path: 'orders',
              component: Orders,
            },
            {
              path: 'offer',
              component: Offer,
            },
            {
              path: 'products',
              component: Products,
            },
            {
              path: 'sets',
              component: ProductSets,
            },
            {
              path: '',
              redirect: 'orders',
            },
          ],
        },
        {
          path: 'shop/availability',
          component: AvailabilityForm,
        },
        {
          path: 'shop/import',
          component: AvailabilityImport,
        },
        {
          path: 'payments',
          component: Payments,
        },
        {
          path: 'payments/bank-statements',
          component: BankStatements,
        },
        {
          path: 'bills/import',
          component: BillsImport,
        },
        {
          path: 'bills/form',
          component: BillForm,
        },
        {
          path: 'payments/form',
          component: PaymentForm,
        },
        {
          path: 'bills',
          component: Bills,
        },
        {
          path: 'bills/plans',
          component: BillingPlans,
        },
        {
          path: 'bills/documents',
          component: BillingDocuments,
        },
        {
          path: 'bills/documents/form',
          component: BillingDocumentsForm,
        },
        {
          path: 'reports/balance',
          component: BalanceReport,
        },
        // {
        //   path: 'reports/settlement',
        //   component: SettlementReport,
        // },
        {
          path: 'reports',
          redirect: 'reports/balance',
        },
        {
          path: 'messages',
          component: Messages,
          children: [
            {
              path: 'messages',
              component: InternalMessages,
            },
            {
              path: 'notifications',
              component: Notifications,
            },
            {
              path: '',
              redirect: 'messages',
            },
          ],
        },
        {
          path: 'settings/form/:id',
          component: FormConfiguration,
          props: true,
        },
        {
          path: 'settings',
          component: Settings,
          children: [
            {
              path: 'info',
              component: OrganizationInfo,
            },
            {
              path: 'currencies',
              component: CurrenciesProperties,
            },
            {
              path: 'payers',
              component: PayersSettings,
            },
            {
              path: 'shop',
              component: ShopSettings,
            },
            {
              path: 'bills',
              component: BillSettings,
            },
            {
              path: 'payments',
              component: PaymentsSettings,
            },
            {
              path: 'accounting',
              component: AccountingSettings,
            },
            {
              path: 'notifications',
              component: NotificationSettings,
            },
            {
              path: 'settlements',
              component: Settlements,
            },
            {
              path: 'forms',
              component: FormsSettings,
            },
            {
              path: 'access',
              component: AccessManagement,
            },
            {
              path: '',
              redirect: 'payers',
            },
          ],
        },
      ],
    },
  ],
};
