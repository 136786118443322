<template>
  <div class="calendar-wrapper">
    <div>
      <div class="d-flex justify-content-between">
        <MonthDropdown
          class="pr-4 mt-2"
          style="max-width: 300px"
          :highlight-day="firstDay"
          :selected.sync="billingScope"
        />
      </div>
      <hr class="mb-0">
    </div>

    <div>
      <div
        v-for="d in days"
        :key="d"
        class="day-row d-flex align-items-center"
        :class="{
          weekend: isWeekend(d),
          past: isPast(d),
        }"
      >
        <div class="day-title">
          <div
            v-if="isPast(d)"
            class="past-icon"
          >
            <i class="fa-solid fa-calendar-check" />
          </div>
          {{ formatDay(d) }}
          <div class="text-secondary small">
            {{ weekName(d) }}
          </div>
        </div>
        <div>
          <ProductSelect
            :products="availability[d]"
            @update:products="$set(availability, d, $event); updated()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import MonthDropdown from './MonthDropdown';
import ProductSelect from './ProductSelect';

export default {
  props: {
    calendar: Object,
    firstDay: String,
  },
  data: () => ({
    billingScope: null,
    availability: {},
    time: '10:00',
  }),
  components: {
    MonthDropdown,
    ProductSelect,
  },
  computed: {
    days() {
      if (!this.billingScope) return [];
      const result = [];
      const curr = moment(this.billingScope.from);
      const end = moment(this.billingScope.to);

      while (curr.isBefore(end)) {
        result.push(curr.startOf('day').format('YYYY-MM-DD'));
        curr.add(1, 'day');
      }

      return result;
    },
  },
  methods: {
    formatDay(day) {
      return moment(day).format('DD MMMM');
    },
    isWeekend(day) {
      const weekday = moment(day).isoWeekday();
      return weekday >= 6;
    },
    isPast(day) {
      return moment(day).isSameOrBefore(moment());
    },
    weekName(day) {
      return moment(day).format('dddd');
    },
    updated() {
      this.$emit('update:calendar', this.availability);
    },
  },
  created() {
    this.availability = { ...this.calendar };
  },
};
</script>

<style lang="scss" scoped>
.day-row {
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 15px 5px;
  border-left: 4px solid transparent;
  position: relative;

  &.weekend {
    background-color: #f9f9f9;
  }
  &.past {
    border-left: 4px solid #333;
    padding-left: 10px
  }
}

.past-icon {
  position: absolute;
  top: 5px;
  right: 7px;
  font-size: 15px;
  color: #333;
}
.day-title {
  font-size: 18px;
  width: 180px;
}

.calendar-wrapper {
  overflow: auto;
  height: calc(100vh - 230px);
  padding-right: 10px;
}
</style>
