<template>
  <div class="d-flex align-items-center">
    <ProductDropdown
      :skip-product-ids="selected.map(x => x.id)"
      @selected="selected.push($event)"
    />
    <div class="ml-3">
      <div
        v-for="p in selected"
        :key="p.id"
        class="selected-product d-flex align-items-center"
      >
        <div
          class="product-remove mr-2"
          @click="remove(p.id)"
        >
          <i class="far fa-xmark" />
        </div>
        <div class="flex-grow-1">
          {{ p.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductDropdown from './ProductDropdown';

export default {
  props: {
    products: Array,
  },
  data: () => ({
    selected: [],
  }),
  components: {
    ProductDropdown,
  },
  watch: {
    selected() {
      this.$emit('update:products', this.selected);
    },
  },
  methods: {
    remove(productId) {
      this.selected = this.selected.filter((x) => x.id !== productId);
    },
  },
  created() {
    this.selected = this.products || [];
  },
};
</script>

<style lang="scss" scoped>
.selected-product {
  background-color: #f4f4f4;
  padding: 2px 10px 2px 5px;
  border-radius: 10px;
  margin-top: 5px;
}

.product-remove {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  font-size: 14px;
  line-height: 23px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: rgba(#333, 0.2);
  }
}
</style>
