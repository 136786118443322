<template>
  <div class="page-cart mb-2 pt-4">
    <div
      class="d-flex justify-content-between align-items-center pb-3"
    >
      <div class="d-flex align-items-center px-2">
        <div class="product-icon">
          <i
            class="fas fa-pot-food"
          />
        </div>
        <div
          class="product-name"
        >
          {{ product.name }}
          <div class="small text-secondary">
            {{ product.code }}
          </div>
        </div>
      </div>
      <div
        v-if="!focus"
        class="d-flex"
      >
        <div class="px-2">
          <Tippy
            interactive
            arrow
            theme="light"
            trigger="click"
          >
            <template #trigger>
              <i
                v-tippy
                class="fas fa-trash secondary-icon"
                :content="$t('general.remove')"
              />
            </template>

            <div v-if="!pendingRemove">
              <button
                class="btn btn-block btn-danger"
                @click="remove"
              >
                <i class="fas fa-trash pr-2" />
                {{ $t('general.confirmRemove') }}
              </button>
            </div>
            <div v-else>
              <Loader size="22px" />
            </div>
          </Tippy>
        </div>
        <div class="pl-2">
          <i
            v-if="details"
            v-tippy
            class="fas fa-pen secondary-icon smaller-icon"
            :content="$t('general.edit')"
            @click="$emit('edit', details)"
          />
        </div>
      </div>
    </div>

    <div
      v-if="details"
    >
      <div class="row no-gutters align-items-center mt-2">
        <div class="col-4">
          <span class="pr-2 mb-0 small text-secondary">
            <i class="fas fa-file-lines pr-1" />
            {{ $t('general.description') }}
          </span>
        </div>
        <div class="small col-8">
          {{ details.description || '-' }}
        </div>
      </div>

      <div class="row no-gutters align-items-center mt-2">
        <div class="col-4">
          <span class="pr-2 mb-0 small text-secondary">
            <i class="fas fa-tags pr-1" />
            {{ $t('shop.tags') }}
          </span>
        </div>
        <div class="small col-8">
          <span
            v-for="t in details.tags"
            :key="t"
            class="group-label mr-1"
          >
            {{ t }}
          </span>
          <span v-if="details.tags.length === 0">-</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    product: Object,
  },
  data: () => ({
    focus: false,
    details: null,
    pendingRemove: false,
  }),
  methods: {
    ...mapActions([
      'getProduct',
      'removeProduct',
    ]),
    request() {
      this.getProduct({
        params: {
          productId: this.product.id,
        },
      })
        .then(({ data }) => {
          this.details = data;
        });
    },
    remove() {
      this.pendingRemove = true;
      this.removeProduct({
        params: {
          productId: this.product.id,
        },
      })
        .then(() => {
          this.$emit('removed');
        })
        .finally(() => {
          this.pendingRemove = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
.product-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
}

.product-icon {
  font-size: 25px;
  margin-right: 15px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 42px;
  background-color: #efefef;
}

.description {
  white-space: pre-line;
}
</style>
