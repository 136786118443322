<template>
  <div class="h-100">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <SearchBox
          v-model="search"
          lazy
          class="mr-3"
        />
      </div>
      <div class="d-flex">
        <button
          class="btn btn-sm btn-primary mr-2"
          @click="$router.push(`/payee/${payeeId}/shop/import`)"
        >
          <i class="fas fa-upload pr-2" />
          {{ $t('menu.import') }}
        </button>
        <button
          class="btn btn-sm btn-primary"
          @click="productSetModal = true; updatedProductSet = null"
        >
          <i class="fas fa-add pr-2" />
          {{ $t('shop.addProductSet') }}
        </button>
      </div>
    </div>
    <hr class="mb-1">
    <div>
      <div
        class="table-header-row d-none d-md-flex w-100"
      >
        <div class="pl-2 flex-grow-1">
          <label class="mb-0 column-header">
            {{ $t('general.name') }}
          </label>
        </div>
        <div
          v-if="!edited && multipleProductSetTypes"
          style="width: 200px"
          class="left-border"
        >
          <label class="mb-0 column-header">
            {{ $t('shop.settings.productSetType') }}
          </label>
        </div>
        <div
          v-if="!edited"
          style="width: 200px"
          class="left-border"
        >
          <label class="mb-0 column-header">
            {{ $t('bill.category') }}
          </label>
        </div>
      </div>
      <ColumnEdit
        :edit="!!edited"
      >
        <div class="scroll-sets">
          <div
            v-for="p in filteredProductSets"
            :key="p.id"
            class="table-row"
            :class="{ selected: edited && edited.id === p.id }"
            @click="toggleEdit(p)"
          >
            <div class="cell d-flex w-100 align-items-center">
              <div class="flex-grow-1">
                <i class="fas fa-fork-knife product-icon" />
                {{ p.name }}
              </div>
              <div
                v-if="!edited && multipleProductSetTypes"
                style="width: 200px"
                class="small left-border"
              >
                {{ p.typeName }}
              </div>
              <div
                v-if="!edited"
                style="width: 200px"
                class="small left-border"
              >
                <CategoryInfo :category-id="p.categoryId" />
              </div>
            </div>
          </div>
        </div>

        <template #edit>
          <div
            v-if="edited"
            class="p-2 page-content position-relative"
          >
            <div
              class="sticky-top pt-1 px-4 page-content w-100"
              style="max-height: 100vh; overflow: auto; top: 50px; min-height: 100px"
            >
              <CloseButton
                style="position: absolute; right: 8px; top: 0px; z-index: 100"
                @click.native.stop="toggleEdit(edited)"
              />
              <Transition
                name="client-details"
                mode="out-in"
              >
                <ProductSetDetails
                  v-if="detailsReady"
                  :key="edited.id"
                  :show-type="multipleProductSetTypes"
                  :product-set="edited"
                  @edit="updatedProductSet = $event; productSetModal = true"
                  @close="edited = null"
                  @refresh="request"
                />
              </Transition>
            </div>
          </div>
        </template>
      </ColumnEdit>
    </div>

    <BModal
      v-model="productSetModal"
      hide-footer
      hide-header
      size="lg"
    >
      <CloseButton
        style="position: absolute; right: -8px; top: -10px; z-index: 100"
        @click.native.stop="productSetModal = false"
      />
      <div
        class="font-weight-bold"
        style="font-size: 20px"
      >
        {{ updatedProductSet ? updatedProductSet.name : $t('shop.newProductSet') }}
      </div>
      <div class="p-3">
        <ProductSetForm
          :product-set="updatedProductSet"
          :config="config"
          @close="productSetModal = false"
          @refresh="request"
        />
      </div>
    </BModal>
  </div>
</template>

<script>
import CategoryInfo from '@/components/CategoryInfo';
import ColumnEdit from '@/components/layout/ColumnEdit';
import ProductSetDetails from '@/components/shop/ProductSetDetails';
import ProductSetForm from '@/components/shop/ProductSetForm';
import createSearch from '@/utils/search';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    productSetModal: false,
    productSets: [],
    highlighted: [],
    edited: null,
    search: '',
    updatedProductSet: null,
    detailsReady: false,
    config: null,
  }),
  components: {
    ColumnEdit,
    CategoryInfo,
    ProductSetForm,
    ProductSetDetails,
  },
  computed: {
    ...mapGetters(['payeeId']),
    filteredProductSets() {
      const search = createSearch(this.search);
      return (this.productSets || [])
        .filter((x) => search(x.name))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    multipleProductSetTypes() {
      return this.config && this.config.productSetTypes.length > 1;
    },
  },
  watch: {
    edited(e) {
      if (e) {
        this.$router.replace({ query: { productSetId: e.id } });
      } else {
        this.$router.replace({ query: { productSetId: undefined } });
      }
    },
  },
  methods: {
    ...mapActions([
      'getProductSets',
      'getProducts',
      'getShopConfig',
    ]),
    toggleEdit(product) {
      clearTimeout(this.timeout);
      this.detailsReady = false;
      if (this.edited == null || this.edited.id !== product.id) {
        this.edited = product;
        this.timeout = setTimeout(() => {
          this.detailsReady = true;
        }, 200);
      } else {
        this.detailsReady = false;
        this.edited = null;
      }
    },
    request() {
      this.getShopConfig()
        .then(({ data }) => {
          this.config = data;
        });

      this.getProducts();
      this.getProductSets()
        .then(({ data }) => {
          this.productSets = data;
          const { productSetId } = this.$route.query;
          this.edited = this.productSets.find((x) => x.id === productSetId);
          this.detailsReady = true;
        });
    },
  },
  created() {
    this.$emit('page', 'sets');
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  .product-icon {
    width: 25px;
    color: #9a9a9a;
  }

  .left-border {
    border-left: 1px solid #dddddd;
    padding-left: 0.5rem;
  }

  .scroll-sets {
    overflow: auto;
    height: calc(100vh - 215px);
    padding-bottom: 40px;
  }

  .client-details-enter-active,
  .client-details-leave-active {
    transition: opacity 200ms, transform 200ms;
  }

  .client-details-enter, .client-details-leave-to {
    opacity: 0;
    transform: translateX(-10px);
  }
</style>
