<template>
  <div class="d-flex align-items-end">
    <div class="mx-3">
      <div class="font-weight-bold mt-3">
        {{ $t('shop.reports.hour') }}
      </div>
      <BFormInput
        :value="hour"
        type="text"
        placeholder="HH:mm"
        @input="$emit('update:hour', $event)"
      />
    </div>
    <div class="mx-3">
      <div class="mt-3 d-flex">
        <div class="font-weight-bold">
          {{ $t('shop.reports.forDay') }}
        </div>
        <TooltipHelp
          class="pl-2"
          :width="500"
        >
          <div style="white-space: pre-wrap">
            {{ $t('shop.reports.forDayInfo') }}
          </div>
        </TooltipHelp>
      </div>
      <BFormInput
        :value="dayOffset"
        type="number"
        @input="$emit('update:dayOffset', $event)"
      />
    </div>
    <div class="mx-3">
      <SwitchWithDescription
        :title="$t('report.time.skipWeekend')"
        small
        class="mb-1"
        style="min-height: 44px; margin-top: 0"
        :value="skipWeekend"
        @switch="$emit('update:skipWeekend', !skipWeekend)"
      />
    </div>
  </div>
</template>

<script>
import SwitchWithDescription from '@/components/settings/SwitchWithDescription';

export default {
  props: {
    hour: String,
    skipWeekend: Boolean,
    dayOffset: Number,
  },
  components: {
    SwitchWithDescription,
  },
};
</script>
