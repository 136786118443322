<template>
  <div>
    <div>
      <div class="form-cell">
        <label>{{ $t('general.name') }}</label>
        <BFormInput
          v-model="name"
          :placeholder="$t('general.name')"
        />
      </div>
      <div class="form-cell">
        <label>{{ $t('general.description') }}</label>
        <BFormTextarea
          v-model="description"
          :placeholder="$t('general.description')"
        />
      </div>

      <div class="w-50 form-cell">
        <label>{{ $t('shop.settings.productSetType') }}</label>
        <BFormSelect
          v-model="typeId"
          :state="types.length === 0 ? false : null"
          :options="types"
          style="font-size: 13px; max-width: 300px"
        />
      </div>
      <hr>
      <div
        class="row"
        style="width: 400px; margin-left: auto; margin-right: 0"
      >
        <div class="col-6">
          <button
            class="btn btn-secondary btn-block btn-sm"
            @click="$emit('close')"
          >
            <i class="fas fa-times pr-2" />
            {{ $t('general.cancel') }}
          </button>
        </div>
        <div class="col-6">
          <button
            data-test="save-client-details-button"
            class="btn btn-primary btn-block btn-sm"
            :disabled="pending"
            @click="submit"
          >
            <i class="fas fa-check pr-2" />
            {{ $t('general.save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    productSet: Object,
    config: Object,
  },
  data: () => ({
    name: '',
    code: '',
    description: '',
    tags: [],
    bankAccountId: null,
    typeId: null,
    categoryId: '',
    pending: false,
  }),
  computed: {
    ...mapGetters(['payee']),
    types() {
      return (this.config.productSetTypes || [])
        .map((x) => ({
          text: x.name,
          value: x.id,
        }));
    },
  },
  methods: {
    ...mapActions([
      'addProductSet',
      'updateProductSet',
    ]),
    removeTag(t, index) {
      this.tags = this.tags.filter((x, i) => i !== index);
    },
    addTag() {
      this.tags.push('');
      this.$nextTick(() => {
        const index = this.tags.length - 1;
        const ref = this.$refs[`tag-${index}`][0];
        if (ref) {
          ref.focus();
        }
      });
    },
    sendUpdate() {
      if (this.productSet) {
        return this.updateProductSet({
          params: {
            productSetId: this.productSet.id,
          },
          data: {
            name: this.name,
            typeId: this.typeId,
            description: this.description,
            categoryId: this.categoryId,
            accountNumber: this.bankAccountId,
          },
        });
      }
      return this.addProductSet({
        data: {
          name: this.name,
          typeId: this.typeId,
          description: this.description,
          categoryId: this.categoryId,
          accountNumber: this.bankAccountId,
        },
      });
    },
    submit() {
      this.pending = true;
      this.sendUpdate()
        .then(() => {
          this.$emit('close');
          this.$emit('refresh');
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    if (this.productSet) {
      this.name = this.productSet.name;
      this.description = this.productSet.description;
      this.typeId = this.productSet.typeId;
    }

    if (!this.typeId) {
      this.typeId = this.config.productSetTypes.find((x) => x.isDefault)?.id;
    }
  },
};
</script>

<style lang="scss" scoped>
.form-cell {
  padding: 5px;
}

.product-remove {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 18px;
  line-height: 35px;
  margin-left: 5px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: rgba(#333, 0.2);
  }
}
</style>
