<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <SearchBox
          v-model="search"
          lazy
          class="mr-3"
        />
      </div>

      <div>
        <button
          class="btn btn-sm btn-primary mr-2"
          @click="$router.push(`/payee/${payeeId}/shop/import`)"
        >
          <i class="fas fa-upload pr-2" />
          {{ $t('menu.import') }}
        </button>

        <button
          class="btn btn-sm btn-primary"
          @click="productModal = true"
        >
          <i class="fas fa-add pr-2" />
          {{ $t('shop.addProduct') }}
        </button>
      </div>
    </div>
    <hr class="mb-1">
    <div>
      <ColumnEdit
        :edit="!!edited"
      >
        <div
          class="table-header-row d-none d-md-flex w-100"
        >
          <label class="mb-0 column-header flex-grow-1 pl-2">
            {{ $t('general.name') }}
          </label>
          <label
            class="mb-0 column-header code-column pl-2"
          >
            {{ $t('general.code') }}
          </label>
        </div>
        <div
          style="height: calc(100vh - 220px); overflow: auto"
        >
          <div
            v-for="p in filteredProducts"
            :key="p.id"
            class="table-row"
            :class="{ selected: edited && edited.id === p.id }"
            @click="toggleEdit(p)"
          >
            <div class="cell name-cell flex-grow-1">
              <i class="fas fa-pot-food product-icon" />
              {{ p.name }}
            </div>
            <div class="cell text-secondary code-column small pl-2">
              {{ p.code }}
            </div>
          </div>
        </div>

        <template #edit>
          <div
            v-if="edited"
            class="p-2 page-content position-relative"
          >
            <div
              class="sticky-top pt-1 px-4 page-content w-100"
              style="max-height: 100vh; overflow: auto; top: 50px; min-height: 100px"
            >
              <CloseButton
                style="position: absolute; right: 8px; top: 0px; z-index: 100"
                @click.native.stop="toggleEdit(edited)"
              />
              <Transition
                name="client-details"
                mode="out-in"
              >
                <ProductDetails
                  v-if="detailsReady"
                  :key="edited.id"
                  :product="edited"
                  @edit="updatedProduct = $event; productModal = true;"
                  @removed="removed(edited.id)"
                />
              </Transition>
            </div>
          </div>
        </template>
      </ColumnEdit>
    </div>
    <BModal
      v-model="productModal"
      hide-footer
      hide-header
      size="lg"
      @hidden="edited = null"
    >
      <CloseButton
        style="position: absolute; right: -8px; top: -10px; z-index: 100"
        @click.native.stop="productModal = false"
      />
      <div
        class="font-weight-bold"
        style="font-size: 20px"
      >
        {{ updatedProduct ? updatedProduct.name : $t('shop.newProduct') }}
      </div>
      <div class="p-3">
        <ProductForm
          :product="updatedProduct"
          @close="productModal = false"
          @refresh="request"
        />
      </div>
    </BModal>
  </div>
</template>

<script>
import ColumnEdit from '@/components/layout/ColumnEdit';
import ProductDetails from '@/components/shop/ProductDetails';
import ProductForm from '@/components/shop/ProductForm';
import createSearch from '@/utils/search';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    products: [],
    edited: null,
    search: '',
    updatedProduct: null,
    detailsReady: false,
    productModal: false,
  }),
  components: {
    ProductDetails,
    ProductForm,
    ColumnEdit,
  },
  computed: {
    ...mapGetters(['payeeId']),
    filteredProducts() {
      const search = createSearch(this.search);
      return (this.products || []).filter((x) => search(x.name) || search(x.code));
    },
  },
  methods: {
    ...mapActions(['getProducts']),
    removed(productId) {
      this.products = this.products.filter((x) => x.id !== productId);
      this.edited = null;
      this.detailsReady = false;
    },
    toggleEdit(product) {
      clearTimeout(this.timeout);
      this.detailsReady = false;
      if (this.edited == null || this.edited.id !== product.id) {
        this.edited = product;
        this.timeout = setTimeout(() => {
          this.detailsReady = true;
        }, 200);
      } else {
        this.detailsReady = false;
        this.edited = null;
      }
    },
    request() {
      this.getProducts()
        .then(({ data }) => {
          this.products = data;
        });
    },
  },
  created() {
    this.$emit('page', 'products');
    this.request();
  },
};
</script>

<style lang="scss" scoped>

  .product-icon {
    width: 25px;
    color: #9a9a9a;
  }

  .code-column {
    max-width: 150px;
    min-width: 150px;
  }

  .client-details-enter-active,
  .client-details-leave-active {
    transition: opacity 200ms, transform 200ms;
  }

  .client-details-enter, .client-details-leave-to {
    opacity: 0;
    transform: translateX(-10px);
  }
</style>
