<template>
  <div class="wrapper">
    <div class="d-flex align-items-center justify-content-between mb-1">
      <img
        src="@/assets/logo_black.png"
        class="logo"
      >
      <div
        class="text-center small position-relative data-freshness"
        :class="{ pending }"
        @click="requestOrders"
      >
        <div>
          {{ $t('general.dataFreshness') }}:
        </div>
        <span
          v-if="dataFreshness < 10"
          class="text-success font-weight-bold"
        >
          <i class="fas fa-circle-check pr-2" />
          {{ dataFreshness < 1 ? '< 1' : Math.round(dataFreshness) }} min
        </span>
        <span
          v-else
          class="text-danger font-weight-bold"
        >
          <i class="fas fa-triangle-exclamation pr-2" />
          {{ dataFreshness < 1 ? '< 1' : Math.round(dataFreshness) }} min
        </span>

        <div class="refresh-icon align-items-center justify-content-center">
          <i class="fas fa-arrow-rotate-right" />
        </div>
      </div>
      <div class="current-date text-secondary">
        {{ currentDate }}
      </div>
    </div>
    <hr>
    <div class="row full-height">
      <div
        class="col-12 col-lg-6"
        style="max-height: calc(100% - 60px)"
      >
        <SearchBox
          ref="search"
          v-model="search"
          lazy
          class="my-2"
          constant-width
          @focus="showPayers = true"
          @blur="delayedHide"
        />
        <div
          v-if="!hideListAfterSelect || showPayers"
          style="max-height: 100%; overflow-y: auto"
        >
          <div
            v-for="p in filteredPayers"
            :key="p.id"
            class="list-payer d-flex justify-content-between"
            :class="{ selected: payer && p.id === payer.id }"
            @click="payer = p; showPayers = false"
          >
            <PayerName
              v-bind="p"
              :code="undefined"
              style="width: 60%"
            />

            <div
              v-if="p.code"
              class="small text-secondary text-right"
            >
              {{ p.code }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-show="!hideListAfterSelect || !showPayers || error"
        class="col-12 col-lg-6 h-100"
      >
        <div v-if="error">
          <div
            class="text-danger text-center"
          >
            <div style="font-size: 45px">
              <i class="fas fa-xmark-large" />
            </div>
            <div
              style="font-size: 20px"
              class="font-weight-bold"
            >
              {{ $t('error.error') }}
            </div>
          </div>
        </div>
        <TodayPayerOrder
          v-if="payer"
          :key="payer.id"
          :payer="payer"
          :order-data="orders"
          @delivered="delivered"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PayerName from '@/components/payers/PayerName';
import TodayPayerOrder from '@/components/shop/TodayPayerOrder';
import createSearch from '@/utils/search';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

const breakPoint = 992;

export default {
  props: {
    payeeId: String,
  },
  data: () => ({
    search: '',
    list: [],
    orders: {},
    payer: null,
    showPayers: false,
    hideListAfterSelect: true,
    dataFrom: null,
    now: null,
    pending: false,
  }),
  components: {
    TodayPayerOrder,
    PayerName,
  },
  computed: {
    ...mapGetters(['payee', 'properties']),
    currentDate() {
      return moment(this.now).format('dddd, DD MMM HH:mm');
    },
    dataFreshness() {
      if (!this.dataFrom) return '';
      const seconds = moment(this.now).unix() - moment(this.dataFrom).unix();
      if (seconds < 0) {
        return 0;
      }

      return seconds / 60;
    },
    filteredPayers() {
      const search = createSearch(this.search);
      return this.list.filter((x) =>
        search(x.name)
         || search(x.code)
         || Object.values(x.properties || {}).some((p) => search(p)));
    },
    additionalProperties() {
      // TODO: Mark it somehow different
      return this.properties.filter((x) =>
        x.name.toLowerCase().includes('qr')
        || x.name.toLowerCase().includes('kod')
        || x.name.toLowerCase().includes('barcode'));
    },
  },
  methods: {
    ...mapActions([
      'getOrders',
      'getPayers',
      'getPayersProperties',
    ]),
    delivered(payerId) {
      const o = this.orders.orders.find((x) => x.payerId === payerId);
      if (o) {
        o.delivered += 1;
      }
    },
    delayedHide() {
      // Delay blur, to handle click
      if (this.payer) {
        setTimeout(() => { this.showPayers = false; }, 100);
      }
    },
    selectFirst(search) {
      this.search = search;
      this.$nextTick(() => {
        if (this.filteredPayers.length > 0) {
          [this.payer] = this.filteredPayers;
        }
        this.search = '';
        this.$refs.search.set('');
        this.$refs.search.blur();
        this.showPayers = false;
      });
    },
    requestOrders() {
      this.error = false;
      this.pending = true;

      this.getOrders({
        params: {
          query: {
            from: moment().format('YYYY-MM-DD'),
            to: moment().add(1, 'day').format('YYYY-MM-DD'),
          },
        },
      })
        .then(({ data }) => {
          this.orders = data;
          this.dataFrom = moment().format();
          this.pending = false;
        })
        .catch(() => {
          this.pending = false;
          this.error = true;
        });
    },
    request() {
      return this.getPayers({
        params: {
          query: {
            skip: 0,
            take: -1,
            withProperty: this.additionalProperties.map((x) => x.code),
          },
        },
      })
        .then(({ data }) => {
          this.list = data;
          return data;
        });
    },
  },
  created() {
    this.$store.commit('setPayeeId', this.payeeId);
    this.requestOrders();
    this.getPayersProperties()
      .then(() => {
        this.request();
      });

    this.updateHideListAfterSelect = () => {
      this.hideListAfterSelect = window.innerWidth < breakPoint;
    };

    this.keyDown = (e) => {
      const text = this.$refs.search.search;

      if (e.keyCode === 13) {
        setTimeout(() => {
          this.selectFirst(text);
        }, 50);
      } else if (!this.showPayers) {
        const isChar = typeof e.which === 'number'
          && e.which > 0
          && !e.ctrlKey
          && !e.metaKey
          && !e.altKey
          && e.which !== 8;

        if (e.keyCode === 8) {
          this.$refs.search.set(text.substring(0, text.length - 1));
        } else if (e.keyCode !== 13 && isChar) {
          this.$refs.search.set(`${text}${e.key}`);
        }
      }
    };

    this.now = moment().format();
    this.nowInterval = setInterval(() => {
      this.now = moment().format();
    }, 5000);

    this.updateHideListAfterSelect();
    window.addEventListener('resize', this.updateHideListAfterSelect);
    window.addEventListener('keydown', this.keyDown);
  },
  destroyed() {
    window.removeEventListener('resize', this.updateHideListAfterSelect);
    window.removeEventListener('keydown', this.keyDown);
    if (this.nowInterval) {
      clearInterval(this.nowInterval);
    }
  },
  mounted() {
    this.$refs.search.focus();
  },
};
</script>

<style lang="scss" scoped>
.logo {
  height: 30px;
}

.current-date {
  font-weight: 500;
}

.payer-icon {
  color: $light-gray;
}

.list-payer {
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 10px;
  margin-right: 5px;
  margin-top: 2px;
  cursor: pointer;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid rgba($blue, 0.2);
  }

  &.selected {
    background-color: rgba($blue, 0.2);
  }
}

.data-freshness {
  cursor: pointer;

  .refresh-icon {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #eee;
    top: 0;
    left: 0;
    opacity: 0.8;

    i {
      font-size: 20px;
    }
  }

  &.pending {

    .refresh-icon {
      display: flex;
      i {
        animation-name: rotate;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
      }
    }
  }

  &:hover {
    .refresh-icon {
      display: flex;
    }
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.full-height {
  height: calc(100vh - 100px);
}

@media screen and (max-width: 991px) {
  .full-height {
    height: calc(100vh - 150px);
  }
}

.wrapper {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
