<template>
  <div>
    <div class="d-flex align-items-center flex-wrap">
      <GroupOrdersDropdown
        class="mr-3 mb-1"
        :group-key.sync="groupKey"
      />
      <CalendarDropdown
        :highlighted.sync="highlighted"
        :available-days="availableDays"
        :day-status="dayStatus"
        :dates.sync="dates"
        class="mr-3 mb-1"
        @title="selectTitle = $event"
      />
      <PayersFilterDropdown
        class="mr-3 mb-1"
        @selected="selectedPayers = $event"
      />
      <SwitchWithDescription
        :title="$t('shop.showUnpaid')"
        small
        class="mb-1"
        style="min-height: 44px; margin-top: 0"
        :value="showUnpaid"
        @switch="showUnpaid = !showUnpaid"
      />
      <div
        v-if="!pendingDownload"
        v-tippy
        class="secondary-icon ml-2"
        :content="$t('shop.downloadReport')"
        @click="download"
      >
        <i class="fas fa-download" />
      </div>
      <div v-else>
        <Loader />
      </div>
      <ReportSendSettings />
      <div
        class="flex-grow-1 d-flex align-items-center justify-content-end"
      >
        <div>
          <button
            class="btn btn-sm btn-primary"
            data-test="toggle-add-client"
            @click="addingOrder = true"
          >
            <i class="fas fa-plus pr-1" />
            {{ $t('shop.addOrders') }}
          </button>
        </div>
      </div>
    </div>

    <BModal
      v-model="addingOrder"
      hide-footer
      size="lg"
      hide-header
    >
      <AddOrderForm
        :product-sets="productSets"
        @close="addingOrder = false"
        @refresh="request"
      />
    </BModal>

    <OrdersList
      v-if="!pending"
      class="flex-grow-1"
      :dates="dates"
      :orders="ordersInSelectedDays"
      :group-by="groupKey"
      :payers="orders.payers"
      :product-sets="orders.productSets"
      @refresh="request"
    />
    <div
      v-else
      class="mt-4"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import PayersFilterDropdown from '@/components/payers/PayersFilterDropdown';
import SwitchWithDescription from '@/components/settings/SwitchWithDescription';
import AddOrderForm from '@/components/shop/report/AddOrderForm';
import CalendarDropdown from '@/components/shop/report/CalendarDropdown';
import GroupOrdersDropdown from '@/components/shop/report/GroupOrdersDropdown';
import OrdersList from '@/components/shop/report/OrdersList';
import ReportSendSettings from '@/components/shop/report/ReportSendSettings';
import getFileName from '@/utils/file-name';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

const showUnpaidStorageKey = 'shop_showUnpaid';
const groupByStorageKey = 'shop_groupBy';

export default {
  data: () => ({
    dates: null,
    orders: {
      orders: [],
      productSets: [],
    },
    addingOrder: false,
    selectTitle: '',
    showUnpaid: true,
    groupKey: 'productSets',
    highlighted: [],
    productSets: [],
    selectedPayers: [],
    pendingDownload: false,
    pending: false,
    previousFrom: null,
  }),
  components: {
    OrdersList,
    AddOrderForm,
    CalendarDropdown,
    SwitchWithDescription,
    GroupOrdersDropdown,
    ReportSendSettings,
    PayersFilterDropdown,
  },
  computed: {
    ...mapGetters(['payeeId']),
    availableDays() {
      return Object.keys(this.dayStatus);
    },
    filteredOrders() {
      return this.orders.orders
        .filter((x) => this.showUnpaid || x.isPaid)
        .filter((x) => this.selectedPayers.length === 0 || this.selectedPayers.some((p) => p.id === x.payerId));
    },
    ordersInSelectedDays() {
      return this.filteredOrders
        .filter((x) => this.highlighted.length === 0 || this.highlighted.includes(x.day));
    },
    dayStatus() {
      return this.filteredOrders.reduce((acc, curr) => {
        if (!acc[curr.day]) {
          acc[curr.day] = {
            paid: 0,
            waiting: 0,
          };
        }

        if (curr.isPaid) {
          acc[curr.day].paid += 1;
        } else {
          acc[curr.day].waiting += 1;
        }
        return acc;
      }, {});
    },
    everyday() {
      if (!this.dates) return [];
      const curr = moment(this.dates.from);
      const end = moment(this.dates.to);
      const result = [];

      while (curr.isBefore(end)) {
        result.push(curr.format('YYYY-MM-DD'));
        curr.add(1, 'day');
      }

      return result;
    },
    reportDays() {
      return this.highlighted.length === 0
        ? this.everyday
        : this.highlighted;
    },
  },
  watch: {
    dates() {
      if (this.debounceRequest) {
        clearTimeout(this.debounceRequest);
      }

      this.debounceRequest = setTimeout(() => {
        this.request();
      }, 300);
    },
    showUnpaid(v) {
      if (v) {
        localStorage.setItem(showUnpaidStorageKey, v);
      } else {
        localStorage.removeItem(showUnpaidStorageKey);
      }
    },
    groupKey(v) {
      if (v === 'productSets') {
        localStorage.removeItem(groupByStorageKey, v);
      } else {
        localStorage.setItem(groupByStorageKey, v);
      }
    },
  },
  methods: {
    ...mapActions([
      'getOrders',
      'getProductSets',
      'getOrdersReport',
    ]),
    download() {
      this.pendingDownload = true;

      this.getOrdersReport({
        data: {
          days: this.reportDays,
          showUnpaid: this.showUnpaid,
          payerIds: this.selectedPayers.map((x) => x.id),
          properties: ['name', 'code'],
        },
      })
        .then(({ data, headers }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;

          link.setAttribute('download', getFileName(headers));
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.pendingDownload = false;
        });
    },
    request() {
      if (!this.dates) return;
      this.pending = true;
      this.getOrders({
        params: {
          query: {
            from: this.dates.from,
            to: this.dates.to,
          },
        },
      })
        .then(({ data, config }) => {
          if (this.dates.from === config.params.query.from) {
            this.orders = data;
          }
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.getProductSets()
      .then(({ data }) => {
        this.productSets = data;
      });

    this.showUnpaid = !!localStorage.getItem(showUnpaidStorageKey);
    if (localStorage.getItem(groupByStorageKey) === 'payers') {
      this.groupKey = 'payers';
    }

    this.$emit('page', 'orders');
  },
};
</script>
