<template>
  <div
    v-if="payee?.shopEnabled"
    class="h-100 position-relative"
  >
    <div class="page-title-wrapper">
      <RouterLink
        :to="`/payee/${payeeId}/shop/orders`"
        class="page-title"
        :class="{ active: page === 'orders' }"
      >
        {{ $t('shop.orders') }}
      </RouterLink>
      <RouterLink
        :to="`/payee/${payeeId}/shop/offer`"
        class="page-title ml-4"
        :class="{ active: page === 'offer' }"
      >
        {{ $t('shop.offer') }}
      </RouterLink>
      <RouterLink
        :to="`/payee/${payeeId}/shop/sets`"
        class="page-title ml-4"
        :class="{ active: page === 'sets' }"
      >
        {{ $t('shop.productSets') }}
      </RouterLink>
      <RouterLink
        :to="`/payee/${payeeId}/shop/products`"
        class="page-title ml-4"
        :class="{ active: page === 'products' }"
      >
        {{ $t('shop.products') }}
      </RouterLink>
    </div>

    <RouterLink
      class="light-button"
      :to="`/payee/${payeeId}/order/today`"
      target="_blank"
    >
      <div
        v-tippy
        :content="$t('shop.screenForDelivery')"
        style="right: 30px"
        @click="$router.push()"
      >
        <i class="fa-solid fa-person-carry-box secondary-icon" />
      </div>
    </RouterLink>

    <RouterView
      @page="page = $event"
    />
  </div>
  <div
    v-else
    class="shop-baner"
  >
    <i class="far fa-store" />
    <div v-html="$t('shop.thisWillBeAShop')" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    page: 'shop',
  }),
  computed: {
    ...mapGetters(['payeeId', 'payee']),
  },
  created() {
    this.$emit('page', 'shop');
  },
};
</script>

<style lang="scss" scoped>

  .light-button {
    position: absolute;
    top: 20px;
    right: -10px;
  }

  .shop-baner {
    padding-top: 50px;
    max-width: 520px;
    margin: 0 auto;
    text-align: center;
    color: #aaa;

    i {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
</style>
