<template>
  <div
    v-if="!summary"
    class="text-center mt-3"
  >
    <Loader />
  </div>
  <div
    v-else
    style="max-width: 800px; margin-top: -20px"
  >
    <div
      v-for="timeFrame in timeFrames"
      :key="timeFrame.from"
    >
      <div class="d-flex justify-content-between align-items-end">
        <div class="text-capitalize month-name">
          {{ timeFrame.name }}
        </div>
      </div>

      <ProductSetRow
        v-for="set in timeFrame.productSets"
        :key="set.productSetId"
        v-bind="set"
        :show-description="false"
        :summary="true"
        :ordered="set.unpaidCount + set.paidCount"
        :paid="set.unpaidCount === 0"
        :selected="productSetInBasket(set)"
        :available-count="timeFrame.selectedTypeIds.includes(set.productSetTypeId)
          ? 0
          : set.availableCount"
        @order="(set.unpaidCount + set.paidCount) > 0
          ? showOrderCalendar(set, timeFrame)
          : toggleBasket(set, timeFrame.name)"
        @cancel="showCancelCalendar(set, timeFrame)"
        @showCalendar="showCalendar(timeFrame)"
      />
    </div>
    <div
      v-if="timeFrames.length === 0"
      class="text-secondary text-center pt-4"
    >
      <i
        class="fa-solid fa-shop-lock pb-3"
        style="font-size: 25px;"
      />
      <div>
        {{ $t('shop.offerNotAvailable') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ProductSetRow from './ProductSetRow';

export default {
  props: {
    payerId: String,
    payeeId: String,
  },
  data: () => ({
    summary: null,
    orderedPending: false,
  }),
  components: {
    ProductSetRow,
  },
  computed: {
    ...mapGetters(['billingScopeName', 'productSetInBasket']),
    ...mapState({
      basket: (state) => state.userBills.basket,
    }),
    timeFrames() {
      return this.summary
        .map((x) => ({
          ...x,
          name: this.billingScopeName({ ...x, type: x.scopeType }),
          productSets: Object.entries(x.productSets
            .reduce((acc, curr) => {
              if (!acc[curr.productSetId]) {
                acc[curr.productSetId] = [];
              }
              acc[curr.productSetId].push(curr);
              return acc;
            }, {}))
            .map(([key, sets]) => ({
              productSetId: key,
              productSetTypeId: sets[0].productSetTypeId,
              currency: sets[0].currency,
              availabilityIds: sets.map((s) => s.availabilityId),
              timeFrame: x.from,
              firstCancelAvailable: sets.find((s) => s.firstCancelAvailable)?.firstCancelAvailable,
              availableCount: sets.reduce((acc, curr) => acc + curr.availableCount, 0),
              paidCount: sets.reduce((acc, curr) => acc + curr.paidCount, 0),
              unpaidCount: sets.reduce((acc, curr) => acc + curr.unpaidCount, 0),
              payerId: this.payerId,
              isPaid: false,
              isReturned: false,
              maxPrice: Math.max(...sets.map((s) => s.maxPrice)),
              minPrice: Math.min(...sets.map((s) => s.minPrice)),
              productSet: {
                name: sets[0].name,
                description: sets[0].description,
              },
              days: sets.flatMap((y) => y.days.map((d) => ({
                ...d,
                count: 1,
                availabilityId: y.availabilityId,
              }))),
            }))
            .sort((a, b) => a.productSet.name.localeCompare(b.productSet.name)),
        }))
        .map((x) => ({
          ...x,
          selectedTypeIds: x.productSets
            .filter((s) => this.productSetInBasket(s))
            .map((y) => y.productSetTypeId),
        }))
        .filter((x) => x.productSets.length > 0);
    },
  },
  methods: {
    ...mapActions([
      'getOrdersSummary',
      'getOrdered',
    ]),
    showCalendar({ from }) {
      this.$emit('calendar', from);
    },
    toggleBasket(set, timeFrameName) {
      if (this.productSetInBasket(set)) {
        set.availabilityIds.forEach((aid) => {
          this.$store.commit('removeProductSetFromBasket', {
            payerId: this.payerId,
            productSetId: set.productSetId,
            availabilityId: aid,
            isPaid: false,
            isReturned: false,
            timeFrame: set.timeFrame,
          });
        });
      } else {
        set.availabilityIds.forEach((aid) => {
          const availabilityDays = set.days
            .filter((x) => x.availabilityId === aid);

          const totalPrice = availabilityDays
            .reduce((acc, curr) => acc + curr.price, 0);

          this.$store.commit('addProductSetToBasket', {
            payerId: this.payerId,
            isReturned: false,
            availabilityId: aid,
            productSetId: set.productSetId,
            productSetName: set.productSet.name,
            payeeId: this.payeeId,
            timeFrame: set.timeFrame,
            isPaid: false,
            currency: set.days[0].currency,
            value: totalPrice,
            days: availabilityDays,
            timeFrameName,
          });
        });
      }
    },
    requestOrders() {
      this.getOrdersSummary({
        params: {
          query: {
            payerId: this.payerId,
          },
        },
      })
        .then(({ data }) => {
          this.summary = data;
        });
    },
  },
  created() {
    this.requestOrders();
    this.refreshOrders = () => this.requestOrders();
    this.$root.$on('order-created', this.refreshOrders);
  },
  destroyed() {
    this.$root.$off('order-created', this.refreshOrders);
  },
};
</script>

<style lang="scss" scoped>
.set-box {
  box-shadow: 0 2px 4px #eee;
}

.month-name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 40px;
}

.calendar-button {
  margin-bottom: 10px;
  font-size: 12px;
  margin-right: 15px;
  color: $blue;
}
</style>
